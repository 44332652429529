<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button icon="pi pi-print" @click="printPage()" label="طباعه" />
      </template>

      <template #right>
        <Calendar
          placeholder="من"
          v-model="search.start"
          dateFormat="dd/mm/yy"
        />
        <Calendar
          style="margin-right: 9px;"
          placeholder="الى"
          v-model="search.end"
          dateFormat="dd/mm/yy"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-success"
          icon="pi pi-search"
          @click="searchAdd()"
          v-tooltip="'بحث'"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-help"
          icon="pi pi-download"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      id="print"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه انحراف تنفيذ العقود
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column
        field="id"
        header="المسلسل"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['id']"
            class="p-column-filter"
            placeholder="بحث بالمسلسل"
          />
        </template>
      </Column> -->
      <Column
        field="code"
        header="رقم العقد"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['code']"
            class="p-column-filter"
            placeholder="بحث برقم العقد"
          />
        </template>
      </Column>
      <Column
        field="clientsId.code"
        header="كود العميل"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['clientsId.code']"
            class="p-column-filter"
            placeholder="بحث بكود العميل"
          />
        </template>
      </Column>
      <Column field="clientsId.name" header="اسم العميل" :sortable="true">
        <template #filter>
          <!-- <select
            class="form-select form-control p-column-filter"
            id="clientsId"
            name="clientsId"
            v-model="filters['clientsId.name']"
            placeholder="بحث باسم العميل"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of clientsList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select> -->
          <Dropdown
            :options="clientsList"
            class="form-select form-control text-right"
            style="direction:rtl"
            optionLabel="name"
            optionValue="name"
            :filter="true"
            v-model="filters['clientsId.name']"
            placeholder="بحث باسم العميل"
            :showClear="true"
          />
        </template>
      </Column>
      <Column field="branchesId.name" header="اسم الفرع" :sortable="true">
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="branchesId"
            name="branchesId"
            v-model="filters['branchesId.name']"
            placeholder="بحث بأسم الفرع"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of branchesList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </Column>

      <Column field="usersId.name" header="المندوب">
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="usersId"
            name="usersId"
            v-model="filters['usersId.name']"
            placeholder="بحث بالمندوب"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of usersList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </Column>

      <Column
        field="date"
        header="تاريخ العقد"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="date"
            v-model="filters['date']"
            class="p-column-filter"
            placeholder="بحث بتاريخ العقد"
          />
        </template>
      </Column>

      <Column field="deliveryDate" :sortable="true" header="اقصى تاريخ تسليم" />
      <Column field="doneDate" :sortable="true" header="تاريخ التسليم الفعلى" />

      <Column
        field="total"
        header="قيمه العقد"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['total']"
            class="p-column-filter"
            placeholder="بحث قيمه العقد"
          />
        </template>
      </Column>
      <Column field="days" header="ايام التاخير (الانحراف)" :sortable="true">
        <template #body="slotProps">
          <p
            v-if="slotProps.data.days < 0"
            style="color:red;border: red solid 2px;"
          >
            {{ slotProps.data.days }}
          </p>
          <p v-else style="color:green;border: green solid 2px;">
            {{ slotProps.data.days }}
          </p>
        </template>
        <!-- <template #filter>
          <InputText
            type="number"
            v-model="filters['status']"
            class="p-column-filter"
            placeholder="بحث بالحاله"
          />
        </template> -->
      </Column>

      <!-- aaa -->

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="[slotProps.data]"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="address" header="الموقع" />
            <Column field="note" header="ملاحظات" />
          </DataTable>
          <DataTable
            :value="slotProps.data.productsList"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه المنتجات والخدمات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="productsId.name" header="المنتج" />
            <Column field="itemsId.name" header="الخدمه" />
          </DataTable>
          <DataTable
            :value="slotProps.data.payments"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه الدفعات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="id" header="الكود" />
            <Column field="name" header="الاسم" />
            <Column field="price" header="السعر" />

            <Column
              field="id"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <span v-if="!slotProps2.data.paymentType">
                  لم يتم التحصيل بعد
                </span>
                <span v-else>
                  تم تحصيل الدفعه
                </span>
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>

    <Dialog
      header="الدفع"
      :visible.sync="displayBasic"
      :style="{ width: '80vw' }"
    >
      <div class="p-m-0">
        <div class="form-card row">
          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="date" class="form-label">
                تاريخ المعامله
              </label>
              <input
                class="form-control"
                type="date"
                id="date"
                disabled
                v-model="payment.date"
              />
            </div>
          </div>
          <div class="col-md-4 text-right">
            <div class="mb-3 ">
              <label for="paymentType" class="form-label">
                نوع المعامله
              </label>
              <div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="cach"
                    name="paymentType"
                    value="cach"
                    v-model="payment.paymentType"
                  />
                  <label class="form-check-label" for="cach">كاش</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    id="check"
                    type="radio"
                    name="paymentType"
                    value="check"
                    v-model="payment.paymentType"
                  />
                  <label class="form-check-label" for="check">شيك</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="transfer"
                    name="paymentType"
                    value="transfer"
                    v-model="payment.paymentType"
                  />
                  <label class="form-check-label" for="transfer">تحويل</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="bondNumber" class="form-label">
                رقم السند
              </label>
              <input
                class="form-control"
                type="number"
                id="bondNumber"
                v-model="payment.bondNumber"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="checkNumber" class="form-label">
                رقم الشيك
              </label>
              <input
                :disabled="payment.paymentType != 'check'"
                class="form-control"
                type="number"
                id="checkNumber"
                v-model="payment.checkNumber"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="checkName" class="form-label">
                اسم الشيك
              </label>
              <input
                :disabled="payment.paymentType != 'check'"
                class="form-control"
                id="checkName"
                v-model="payment.checkName"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="checkDate" class="form-label">
                تاريخ الشيك
              </label>
              <input
                :disabled="payment.paymentType != 'check'"
                class="form-control"
                type="date"
                id="checkDate"
                v-model="payment.checkDate"
              />
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-3 ">
              <label for="note" class="form-label">
                ملاحظات
              </label>
              <textarea
                class="form-control"
                type="note"
                id="note"
                v-model="payment.note"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3 ">
              <label for="files" class="form-label">
                مرفقات
              </label>
              <input
                class="form-control"
                type="file"
                id="files"
                multiple
                @change="previewImage"
              />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="حفظ" icon="pi pi-check" @click="closeBasic" autofocus />
        <Button
          label="الغاء"
          icon="pi pi-times"
          @click="displayBasic = false"
          class="p-button-text"
        />
      </template>
    </Dialog>

    <Dialog
      header="التحويل الى التركيبات"
      :visible.sync="displayBasicToInstalltions"
      :style="{ width: '80vw' }"
    >
      <div class="p-m-0">
        <div class="form-card ">
          <div class="mb-3 ">
            <label for="note" class="form-label">
              ملاحظات
            </label>
            <textarea
              class="form-control"
              type="note"
              id="note"
              v-model="installations.note"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="حفظ"
          icon="pi pi-check"
          @click="toAddInstalltions"
          autofocus
        />
        <Button
          label="الغاء"
          icon="pi pi-times"
          @click="displayBasicToInstalltions = false"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return {
      list: [],
      listx: [],
      selectedItems: [],
      displayBasic: false,
      displayBasicToInstalltions: false,
      expandedRows: [],
      loading: true,
      payment: {
        // "2021-11-24"
        date: `${ye}-${mo}-${da}`,
        paymentType: 'cach',
        bondNumber: null,
        checkNumber: null,
        checkName: null,
        checkDate: null,
        note: null,
        files: [],
      },
      installations: {
        note: null,
        contractsId: null,
        date: null,
        usersId: null,
      },
      paymentId: null,
      filters: {},
      branchesList: [],
      clientsList: [],
      usersList: [],
      search: {
        start: null,
        end: null,
      },
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      console.log(document.querySelectorAll('link[rel="stylesheet"], style'));

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    searchAdd() {
      if (this.search.start && !this.search.end) {
        const start = new Date(this.search.start).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() >= start;
        });
      }

      if (!this.search.start && this.search.end) {
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() <= end;
        });
      }

      if (this.search.start && this.search.end) {
        const start = new Date(this.search.start).getTime();
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return (
            new Date(item.createdAt).getTime() >= start &&
            new Date(item.createdAt).getTime() <= end
          );
        });
      }
    },
    toInstalltions(id) {
      const d = new Date();
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

      this.displayBasicToInstalltions = true;
      const user = JSON.parse(localStorage.admin);

      this.installations = {
        note: null,
        contractsId: id,
        date: `${ye}-${mo}-${da}`,
        usersId: user.id,
      };
    },
  
  
    async previewImage(ev) {
      if (ev.target.files.length > 0) {
        const list = [];
        for (const item of ev.target.files) {
          const itm = await this.$toBase64(item);
          await list.push(itm);
        }
        this.payment.files = await list;
      } else {
        this.payment.files = [];
      }
      return await true;
    },
    getData() {
      this.$http.get(`contracts/getContractsByDeliveryDate`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
          this.listx = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    openBasic(id) {
      this.displayBasic = true;
      this.paymentId = id;
    },
    closeBasic() {
      this.displayBasic = false;
      this.$http.put(`payments/${this.paymentId}`, this.payment).then(
        () => {
          this.$toast.add({
            severity: 'success',
            summary: 'تم بنجاح',
            detail: 'تم التعديل بنجاح',
            life: 3000,
          });
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .delete(`contracts/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`contracts/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    this.$http.get(`branches`).then((res) => {
      this.branchesList = res.data;
    });
    this.$http.get(`clients`).then((res) => {
      this.clientsList = res.data;
    });
    this.$http.get(`users`).then((res) => {
      this.usersList = res.data;
    });
    this.getData();
  },
};
</script>

<style>
.roles label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
